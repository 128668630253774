import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import React, { memo, useContext } from 'react';
import { useLocation } from '@reach/router';
import { StaticImage } from 'gatsby-plugin-image';
import TopNavbar from '@/components/dashboard/TopNavbar';
import UserContext from '@/contexts/UserContext';
import LoadingScreen from '@/components/router/LoadingScreen';
import Footer from '@/components/Footer';
import Wrapper from '../components/shared/Wrapper';
import Hero from '../components/landing/Hero';

const Home = () => {
  const { t } = useTranslation();
  //  The current page is not under the management of the PrivateRoute component route, so user data can only be obtained manually.
  const { user, loading } = useContext(UserContext);
  const location = useLocation();
  const searchParameters = new URLSearchParams(location.search);
  const encodedString = searchParameters.get('auth');
  if (encodedString) {
    const authData = decodeURIComponent(window.atob(encodedString));
    // Set data to localstorage
    localStorage.setItem('authdata', authData);
  }

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Wrapper>
      <Helmet>
        <title>{t('shared.appName')}</title>
        <link rel="canonical" href="https://happycv.se/" />
      </Helmet>

      {/* the TopNavbar component can only be rendered after the user data has been successfully retrieved. */}
      {user && <TopNavbar />}
      <div className="container px-8 xl:px-0 text-center md:text-left mt-24">
        <Hero />
      </div>

      <div className="relative -mt-12 lg:-mt-24">
        <svg
          viewBox="0 0 1428 174"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g
              transform="translate(-2.000000, 44.000000)"
              fill="#FFFFFF"
              fillRule="nonzero"
            >
              <path
                d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
                opacity="0.100000001"
              />
              <path
                d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                opacity="0.100000001"
              />
              <path
                d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
                id="Path-4"
                opacity="0.200000003"
              />
            </g>
            <g
              transform="translate(-4.000000, 76.000000)"
              fill="#FFFFFF"
              fillRule="nonzero"
            >
              <path d="M0.457,34.035 C57.086,53.198 98.208,65.809 123.822,71.865 C181.454,85.495 234.295,90.29 272.033,93.459 C311.355,96.759 396.635,95.801 461.025,91.663 C486.76,90.01 518.727,86.372 556.926,80.752 C595.747,74.596 622.372,70.008 636.799,66.991 C663.913,61.324 712.501,49.503 727.605,46.128 C780.47,34.317 818.839,22.532 856.324,15.904 C922.689,4.169 955.676,2.522 1011.185,0.432 C1060.705,1.477 1097.39,3.129 1121.236,5.387 C1161.703,9.219 1208.621,17.821 1235.4,22.304 C1285.855,30.748 1354.351,47.432 1440.886,72.354 L1441.191,104.352 L1.121,104.031 L0.457,34.035 Z" />
            </g>
          </g>
        </svg>
      </div>

      <section className="bg-white border-b py-8">
        <div className="container max-w-7xl mx-auto m-8">
          <h2 className="w-full my-2 text-6xl font-bold leading-tight text-center text-gray-800">
            Enjoy managing CVs
          </h2>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t" />
          </div>
          <div className="flex flex-wrap">
            <div className="w-5/6 sm:w-1/2 p-6">
              <h3 className="text-4xl text-gray-800 font-bold leading-none mb-3">
                We aim to solve small companies headache.
              </h3>
              <p className="text-2xl text-gray-600 mb-8">
                For consulting companies, head hunting companies, the headache
                to orignize candidates CVs can be a nightmare. It is important
                to keep resumes up-to-date while sync with the candidates.
              </p>
            </div>
            <div className="w-full sm:w-1/2 p-6">
              <StaticImage
                src="../../static/images/business.png"
                alt="HappyCV"
              />
            </div>
          </div>

          <div className="flex flex-wrap flex-col-reverse sm:flex-row">
            <div className="w-full sm:w-1/2 p-6 mt-6">
              <StaticImage
                src="../../static/images/organizer.png"
                alt="HappyCV"
              />
            </div>
            <div className="w-full sm:w-1/2 p-6 mt-6">
              <div className="align-middle">
                <h3 className="text-4xl text-gray-800 font-bold leading-none mb-3">
                  Besides word and PDF CV templates, you got a better
                  alternative.
                </h3>
                <p className="text-2xl text-gray-600 mb-8">
                  You have full control of the workflow, which we simplify it
                  with our simple yet powerful online SaaS system.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-white border-b py-8">
        <div className="container mx-auto flex flex-wrap pt-4 pb-12">
          <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
            Key Features
          </h2>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t" />
          </div>
          <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
            <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
              <div className="w-full font-bold text-3xl text-gray-800 px-6">
                Share the CV via a link with Read Only or Editable permission
              </div>
              <StaticImage
                src="../../static/images/share_link.png"
                alt="HappyCV"
              />
            </div>
          </div>
          <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
            <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
              <div className="w-full font-bold text-3xl text-gray-800 px-6">
                Invite co-workers to manage the same CVs together
              </div>
              <StaticImage
                src="../../static/images/collaborate.png"
                alt="HappyCV"
              />
            </div>
          </div>
          <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
            <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
              <div className="w-full font-bold text-3xl text-gray-800 px-6">
                Upload company logo and customize color, font, layout, theme and
                more
              </div>
              <StaticImage
                src="../../static/images/customize.png"
                alt="HappyCV"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-100 py-8">
        <div className="container mx-auto px-2 pt-4 pb-12 text-gray-800">
          <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
            Pricing
          </h2>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t" />
          </div>
          <div className="flex flex-col sm:flex-row justify-center pt-12 my-12 sm:my-4">
            <div className="flex flex-col w-5/6 lg:w-1/4 mx-auto lg:mx-0 rounded-none lg:rounded-l-lg bg-white mt-4">
              <div className="flex-1 bg-white text-gray-600 rounded-t rounded-b-none overflow-hidden shadow">
                <div className="p-8 text-4xl font-bold text-center border-b-4">
                  Basic
                </div>
                <ul className="w-full text-center text-2xl">
                  <li className="border-b py-4">
                    Share Read Only or Editable link
                  </li>
                  <li className="border-b py-4">Upload company Logo</li>
                  <li className="border-b py-4">
                    Customize theme, layout, color, font and more
                  </li>
                  <li className="border-b py-4">
                    Data daily backup and stored on cloud
                  </li>
                  <li className="border-b py-4">
                    Data can be downloaded to local
                  </li>
                  <li className="border-b py-4">
                    Single-Sign-On without registration
                  </li>
                  <li className="border-b py-4">Max. 20 CVs per company</li>
                </ul>
              </div>
              <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
                <div className="w-full pt-6 text-3xl text-gray-600 font-bold text-center">
                  <s>(100 SEK / month)</s>
                  <br />
                  Free
                </div>
              </div>
            </div>
            <div className="flex flex-col w-5/6 lg:w-1/3 mx-auto lg:mx-0 rounded-lg bg-white mt-4 sm:-mt-6 shadow-lg z-10">
              <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
                <div className="w-full p-8 text-4xl font-bold text-center">
                  Standard
                </div>
                <div className="h-1 w-full gradient my-0 py-0 rounded-t" />
                <ul className="w-full text-center text-2xl font-bold">
                  <br />
                  <br />
                  <li className="border-b py-4" />
                  <li className="border-b py-4">Everything in Basic plan</li>
                  <li className="border-b py-4">Unlimited number of CVs</li>
                  <li className="border-b py-4">7 x 24 Email support</li>
                  <li className="border-b py-4">Cancellation anytime</li>
                </ul>
              </div>
              <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
                <div className="w-full pt-6 text-4xl font-bold text-center">
                  <s>(500 SEK / month)</s>
                  <br />
                  300 SEK / month
                </div>
              </div>
            </div>
            <div className="flex flex-col w-5/6 lg:w-1/4 mx-auto lg:mx-0 rounded-none lg:rounded-l-lg bg-white mt-4">
              <div className="flex-1 bg-white text-gray-600 rounded-t rounded-b-none overflow-hidden shadow">
                <div className="p-8 text-4xl font-bold text-center border-b-4">
                  Advanced
                </div>
                <ul className="w-full text-center text-2xl">
                  <li className="border-b py-4">Everything in Standard plan</li>
                  <li className="border-b py-4">Dedicated theme design</li>
                  <li className="border-b py-4">Usage training</li>
                  <li className="border-b py-4">
                    Phone or Online meeting support
                  </li>
                  <li className="border-b py-4">
                    Self-hosting solution on request
                  </li>
                  <li className="border-b py-4">Extra features on request</li>
                </ul>
              </div>
              <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
                <div className="w-full pt-6 text-3xl text-gray-600 font-bold text-center">
                  Contact us
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Change the colour #f8fafc to match the previous section colour */}
      <svg
        className="wave-top"
        viewBox="0 0 1439 147"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1.000000, -14.000000)" fillRule="nonzero">
            <g className="wave" fill="#f8fafc">
              <path d="M1440,84 C1383.555,64.3 1342.555,51.3 1317,45 C1259.5,30.824 1206.707,25.526 1169,22 C1129.711,18.326 1044.426,18.475 980,22 C954.25,23.409 922.25,26.742 884,32 C845.122,37.787 818.455,42.121 804,45 C776.833,50.41 728.136,61.77 713,65 C660.023,76.309 621.544,87.729 584,94 C517.525,105.104 484.525,106.438 429,108 C379.49,106.484 342.823,104.484 319,102 C278.571,97.783 231.737,88.736 205,84 C154.629,75.076 86.296,57.743 0,32 L0,0 L1440,0 L1440,84 Z" />
            </g>
            <g transform="translate(1.000000, 15.000000)" fill="#FFFFFF">
              <g transform="translate(719.500000, 68.500000) rotate(-180.000000) translate(-719.500000, -68.500000) ">
                <path
                  d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
                  opacity="0.100000001"
                />
                <path
                  d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                  opacity="0.100000001"
                />
                <path
                  d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
                  opacity="0.200000003"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
      <section className="container mx-auto text-center py-6 mb-12">
        <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-white">
          Try it without risk
        </h2>
        <div className="w-full mb-4">
          <div className="h-1 mx-auto bg-white w-1/6 opacity-25 my-0 py-0 rounded-t" />
        </div>
        <h3 className="my-4 text-3xl leading-tight">
          Welcome to the modern CV system!
          <br />
          info@orientalent.se
        </h3>
      </section>
      <Footer />
    </Wrapper>
  );
};

export default memo(Home);
